import styled from "styled-components"

const SkillsBox = styled.div`
  a:hover {
    text-decoration: none;
  }

  @media screen and (min-width: 1024px) {
    .certificates p {
      display: inline;
      margin: 0 60px 0 0;
    }
  }
`

export default SkillsBox
